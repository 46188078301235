Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/@scandipwa/page-builder/src/plugin/CmsPage.container.plugin.js'),
require('./../packages/@scandipwa/page-builder/src/plugin/Html.component.plugin.js')]);
import PropTypes from 'prop-types';
import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Style/main';

// TODO: move this out to i18-runtime
PropTypes.string = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
]);

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
