import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer
} from 'SourceComponent/Meta/Meta.container';

export {
    mapDispatchToProps,
    MetaContainer
};

/** @namespace Frontend/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    robots: state.ConfigReducer.robots // get robots by theme config
});

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
