import { STORE_CURRENT_CODE } from 'Component/StoreSwitcher/StoreSwitcher.config';
import BrowserDatabase from 'SourceUtil/BrowserDatabase/BrowserDatabase';
import { CUR_CURRENCY } from 'SourceUtil/Currency';
import {
    appendTokenToHeaders as sourceAppendTokenHeader,
    formatURI,
    getWindowId,
    GRAPHQL_URI, HTTP_201_CREATED, HTTP_410_GONE,
    parseResponse, putPersistedQuery
} from 'SourceUtil/Request/Request';

export * from 'SourceUtil/Request/Request';

/** @namespace Frontend/Util/Request/getGraphqlEndpoint */
export const getGraphqlEndpoint = () => ''.concat(GRAPHQL_URI);

/** @namespace Frontend/Util/Request/appendTokenToHeaders */
export const appendTokenToHeaders = (headers) => {
    const thisHeader = sourceAppendTokenHeader(headers);

    const store_code = BrowserDatabase.getItem(STORE_CURRENT_CODE) || 'default';
    if (window.storeList.includes(store_code)) {
        thisHeader.Store = store_code || 'default';
    }

    const current_currency = BrowserDatabase.getItem(CUR_CURRENCY) || '';
    if (current_currency) {
        thisHeader['Content-Currency'] = current_currency || '';
    }

    return thisHeader;
};

/** @namespace Frontend/Util/Request/getFetch */
export const getFetch = (uri, name) => fetch(uri,
    {
        method: 'GET',
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            'Application-Model': `${ name }_${ getWindowId() }`,
            Accept: 'application/json'
        })
    });

/** @namespace Frontend/Util/Request/postFetch */
export const postFetch = (graphQlURI, query, variables) => fetch(graphQlURI,
    {
        method: 'POST',
        body: JSON.stringify({ query, variables }),
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json'
        })
    });

/** @namespace Frontend/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace Frontend/Util/Request/executeGet/parseResponse/getFetch/then */
            (res) => {
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace Frontend/Util/Request/executeGet/parseResponse/getFetch/then/putPersistedQuery/then */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace Frontend/Util/Request/executeGet/parseResponse/getFetch/then/putPersistedQuery/then/getFetch/then/resolve */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else {
                    resolve(res);
                }
            }
        );
    }));
};

/** @namespace Frontend/Util/Request/executePost */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables));
};
