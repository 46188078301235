import {
    Html as SourceHtml,
    WidgetFactory
} from 'SourceComponent/Html/Html.component';

export {
    WidgetFactory
};

/** @namespace Frontend/Component/Html/Component */
export class HtmlComponent extends SourceHtml {
    parserOptions = {
        // eslint-disable-next-line consistent-return
        replace: (domNode) => {
            const {
                data,
                name: domName,
                attribs: domAttrs
            } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '')
                .replace(/\s/g, '').length) {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const {
                    query: {
                        name,
                        attribs
                    }
                } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }
                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                if ('widget'.indexOf(domName) !== -1) {
                    const { attribs: domAttrs } = domNode.parent;

                    if (domAttrs
                        && domAttrs['data-content-type'] === 'products'
                        && domAttrs['data-appearance'] === 'carousel') {
                        // eslint-disable-next-line no-param-reassign
                        domNode.attribs.carousel = {
                            enable: true,
                            autoplay: domAttrs['data-autoplay'],
                            'autoplay-speed': domAttrs['data-autoplay-speed'],
                            'infinite-loop': domAttrs['data-infinite-loop'],
                            'show-arrows': domAttrs['data-show-arrows'],
                            'show-dots': domAttrs['data-show-dots']
                        };
                    }
                }

                return replace.call(this, domNode);
            }
        }
    };
}

export default HtmlComponent;
