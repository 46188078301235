import Html from 'SourceComponent/Html';

/** @namespace PageBuilder/Component/HtmlCode/Component/HtmlCodeComponent */
export class HtmlCodeComponent extends React.PureComponent {
    render() {
        const {
            BaseHtmlCode
        } = this.props.elements;

        let html = BaseHtmlCode.childEleBag[0];

        // html must be string in empty array case
        if (Array.isArray(html) && html.length === 0) {
            html = '';
        }

        return (
            <BaseHtmlCode.Ele>
                { html
                    ? <Html content={ html } />
                    : null }
            </BaseHtmlCode.Ele>
        );
    }
}

export default HtmlCodeComponent;
